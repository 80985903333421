import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
import { navigate } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Button = makeShortcode("Button");
const SingleImage = makeShortcode("SingleImage");
const Typography = makeShortcode("Typography");
const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "working-from-home"
    }}>{`Working from home`}</h2>
    <Box display="flex" justifyContent="center" mdxType="Box">
  <Button onClick={() => navigate('/m2-parenting-in-pandemic/19-online-learning')} variant="contained" color="primary" mdxType="Button">
    Skip Topic
  </Button>
    </Box>
    <p><strong parentName="p">{`Let’s admit it.`}</strong>{` Remote work can be hard if you’re juggling work and family responsibilities, while also trying to keep yourself and your family healthy and safe.`}</p>
    <p>{`Your teen’s schedule may not be the only one that needs adjusting `}{`—`}{` consider your own routine too.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/work-from-home.svg" alt="Work from home" mdxType="SingleImage" />
    <Typography color="primary" mdxType="Typography">
  Thinking about your working-from-home arrangement, have you considered the following:
    </Typography>
    <Paper mdxType="Paper">
  <Quiz id="quizWFHArrangment1" singleAnswer={true} question="When the situation changes (e.g. a return to work from home or online learning), plan how you can best manage the challenges of work responsibilities and caring for your teen while following the local restrictions." answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizWFHArrangment2" singleAnswer={true} question="If both your own and your teen’s schedules need adjusting, spend some time discussing this with your teen, and come up with new routines together." answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizWFHArrangment3" singleAnswer={true} question="Set a good example for your teen by having good boundaries between work and home time yourself." answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizWFHArrangment4" question="If you didn’t get a chance to talk with your teen during the day, remember to go back to them later on." singleAnswer={true} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizWFHArrangment5" question="If you can, speak with your employer about any work adjustments that can be made  to help balance work responsibilities and caring for your family." singleAnswer={true} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <p>{`If you answered “No” to any of these, think about any adjustments you could make.`}</p>
    <Paper elevation={0} style={{
      backgroundColor: '#bce2e3',
      borderRadius: '20px'
    }} mdxType="Paper">
  <b>Remember: </b>
  <br />
  Don’t be too hard on yourself. Working from home and navigating changing restrictions can take its toll. Remember to
  be kind to yourself and reach out for support if you need it.
    </Paper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      